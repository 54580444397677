import './App.css';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, } from "react-router-dom";

import styled from "styled-components";
import Loader from './Loading';
import { MantineProvider,} from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/charts/styles.css';


const HomePage = lazy(() => import('./pages/Home'));


const AppWrapper = styled.div`
${props => `
width:100vw;
height: 100vh;
z-index: -1;
position: absolute;
`}
`


const App = () => {
  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState("ESP");

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <MantineProvider>
      <AppWrapper id={"id_wrapper"}>
        <Router>
          {loading ? <Loader /> : (
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/" element={<HomePage lang={lang} setLang={setLang} />} />
              </Routes>
            </Suspense>
          )}
        </Router>

        
      </AppWrapper>
    </MantineProvider>
  );
};


export default App;
