import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import FotoLogoOAT from './assets/logoOAT.png';
import FotoLogoOATWhite from './assets/logoOAT_white.png';
import FotoLogo from './assets/logo_fonts.png';
import { Loader, useComputedColorScheme  } from '@mantine/core';


const fade = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const LoaderWrapper = styled.div`
  ${(props) => `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props.dark ? "#242424" : "#fff"};
  z-index: 500;
  `}
`;



const Img = styled.img`
    animation-delay: 2s;
    animation: ${fade} 4s;
    @media (max-width: 800px) {
        width: 80%;
    }
`;

const Loadder = () => {
  const [showFirstImage, setShowFirstImage] = useState(true);

  const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });


  useEffect(() => {
    const interval = setTimeout(() => {
      setShowFirstImage((prev) => !prev);
    }, 2000); 

    return () => clearTimeout(interval);
  }, []);

  return (
    <LoaderWrapper dark={computedColorScheme === "dark"}>
      <Img src={showFirstImage ? computedColorScheme === "dark" ? FotoLogoOATWhite : FotoLogoOAT : FotoLogo} />
      <Loader style={{marginTop:"50px"}} color="blue" size="xl" />
    </LoaderWrapper>
  );
};

export default Loadder;
